@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #282828;
}
.def-btn {
  align-items: center;
  background: linear-gradient(94.53deg, #34b841 18.01%, #029e8c 77.9%);
  border: 0;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  line-height: 2;
  margin-left: 10px;
  min-height: 50px;
  padding: 0 25px;
  text-decoration: none;
  transition: all 0.4s;
  width: auto;
}

h1 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 30px;
}
.login-form input {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  min-height: 50px;
  padding: 15px;
  width: 100%;
  color: #666666;
  font-size: 16px;
}
.login-form input:focus {
  outline: 0;
  box-shadow: none;
}
.login-form label {
  font-weight: 500;
  color: #282828;
  font-size: 16px;
}
a.logo {
  font-weight: 700;
  font-size: 17px;
  font-family: "Merriweather", serif;
}
.dash-left h2 {
  font-weight: 700;
  font-size: 24px;
  font-family: "Merriweather", serif;
  margin-bottom: 14px;
  /* padding-left: 2rem; */
  padding: 1rem 2rem;
}
span.price-sp:last-child select[name="hold"] {
  min-width: 80px;
}
span.price-sp select {
  min-width: 104px;
}
span.price-sp select[name="status"] {
  max-width: 81px;
}
.dash-left ul li a {
  color: #747373;
  font-size: 17px;
  padding: 1rem 2rem;
  display: block;
  font-weight: 500;
}

.dash-left ul li a.active {
  background: #fff;
  color: #029a3f;
  position: relative;
}
.dash-left ul li a.active:after {
  border-left: 4px solid #029a3f;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  content: "";
}
.dash-table table th {
  text-align: left;
  font-size: 14px;
  font-family: "Merriweather", serif;
  font-weight: 700;
  background: #fff;
  padding: 6px;
}
.dash-table table tr td {
  padding: 14px 6px;
  min-height: 60px;
  font-size: 15px;
  color: #444444;
  font-weight: 400;
}
.dash-table table tbody tr:nth-child(odd) td {
  background: #f3fbfc;
  /* border-radius: 10px; */
}

.villa-title h5 {
  font-size: 13px;
  font-weight: 700;
  font-family: "Merriweather", serif;
  margin-bottom: 0;
  line-height: normal;
}
.villa-title h6 {
  font-size: 13px;
  /* font-weight: 700; */
  font-family: "Merriweather", serif;
  margin-bottom: 0;
  line-height: normal;
}
.villa-title small {
  font-weight: 400;
  font-size: 13px;
}
.villa-img {
  -webkit-box-align: center;
  -webkit-box-pack: center;
  align-items: center;
  background-color: #c3c8d4;

  border-radius: 6px;
  display: flex;
  justify-content: center;
  height: 40px;
  overflow: hidden;
  width: 40px;
  position: relative;
  float: left;
  margin-right: 13px;
  flex: none;
  width: 100%;
  height: 88px;
}

.villa-img img {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0 0 0 50%;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
}

.price-select {
  padding: 4px 6px;
  border-radius: 8px;
}

.cursorPointer {
  cursor: pointer;
}
h3.prop-title {
  font-size: 20px;
  font-weight: 700;
  font-family: "Merriweather", serif;
  color: #282828;
}
.sidebar-menu {
  padding-left: 0;
}
.sidebar-menu li a {
  text-decoration: none;
  /* padding: 1rem 1.5rem; */
}

.cursor-pointer {
  cursor: pointer;
}
.mt-30 {
  margin-top: 8rem;
}

.text-decoration-none {
  text-decoration: none;
}
.error-msg {
  color: red;
  font-size: 12px;
  font-weight: 400;
}
